import * as React from 'react';
import { AboutTurtleBackground } from '../components/bgimg/aboutturtlebgimg';
import Helmet from 'react-helmet';

const AboutPage: React.FC = () => {

  return (
    <>
      <Helmet title="About" />
      <section className="spacing-bottom--top section--about">
        <div className="inner inner-padding">
          <h2 className="text-center">BlueValue <br />
          Connecting the World
          </h2>
          <p className="text-center text--intros">Ecosystem Services are the contributions that support, sustain, and enrich human life and are generally divided into four categories; supportive (e.g. hydrological cycle), regulating (e.g. disturbance regulation), provisioning (e.g. food), and cultural services (e.g. recreation).
          </p>
          <div className="text-center section--about-categories">
            <ul className="flex justify-around">
              <li className="text-center py-2 m-2">
                <h4>SUPPORTIVE</h4>
                <div className="about-category--icon mx-auto">
                  <span className="about-category--icon--support"></span>
                </div>
              </li>
              <li className="text-center py-2 m-2">
                <h4>REGULATING</h4>
                <div className="about-category--icon mx-auto">
                  <span className="about-category--icon--regulation"></span>
                </div>
              </li>
              <li className="text-center py-2 m-2">
                <h4>PROVISIONING</h4>
                <div className="about-category--icon mx-auto">
                  <span className="about-category--icon--provision"></span>
                </div>
              </li>
              <li className="text-center py-2 m-2">
                <h4>CULTURAL SERVICES</h4>
                <div className="about-category--icon mx-auto">
                  <span className="about-category--icon--services"></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="relative bg-spacing--extra section--aboutturtle">
        <AboutTurtleBackground
          className={'full-bg about-turtle'}
        />
        <div className="inner inner-padding text-center">
          <h3 className="text-center">What is BlueValue?</h3>
          <p className="text--intros">BlueValue is a searchable database of simplified and useful ecosystem valuation information.</p>
          <h3 className="text-center">What does that mean?</h3>
          <p className="text--intros">The natural world supports, sustains, and enriches human life in numerous ways. Scientists and resource managers refer to these benefits as “ecosystem services”. Although ecosystem services are critical to human well-being, cases in which they have been successfully applied to real policies and decisions are rare. For society to make informed decisions about sustainable use of the environment,  directly linking the valuation  — or quantification — of ecosystem services, to society’s needs, is necessary. BlueValue is that link.</p>
          <h3 className="text-center">Why was BlueValue created?</h3>
          <p className="text--intros"> Many scientists, economists, practitioners, and others around the world have conducted ecosystem valuation studies. However, it can be difficult and time-consuming for decision-makers to find and access the results of those studies. BlueValue offers quick and easy access to actual ecosystem values in numbers. It houses literature from around the world that users can download, cite, bring to meetings, and share with others.</p>
          <p className="text--intros"> The previous version was GecoServ (Gulf Ecosystem Services Valuation Database), which was supported by the United States Environmental Protection Agency’s Gulf Program, National Oceanic and Atmospheric Administration (NOAA), and the Harte Research Institute. Partial support was also provided by the National Oceanic and Atmospheric Administration, Office of Education Educational Partnership Program award (NA16SEC4810009). Its contents are solely the responsibility of the award recipient and do not necessarily represent the official views of the U.S. Department of Commerce, National Oceanic and Atmospheric Administration.</p>
          <h3 className="text-center"> How do I use BlueValue?</h3>
          <ol className="text--intros list-decimal list-inside">
            <li className="float-none">Click on the Search the Database button (below).</li>
            <li className="float-none">Enter your search terms.</li>
            <li className="float-none">Review the list of results.</li>
            <li className="float-none">Download the literature you need, cite the science, bring the information to your next planning meeting, and share the knowledge with others.</li>
          </ol>
          <a href="/search/" className="btn btn-green p-4">Search the Database</a>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
